import Sidebar from "../../components/Sidebar";
import { Container, Row, Col, Card, Table, Button, Form, InputGroup } from 'react-bootstrap';
import { useState, useEffect } from "react";

import setting from '../../setting.json';
import Topbar from "../../components/Topbar";
import VisibilityIcon from '@mui/icons-material/Visibility';
import React from 'react';


const Feeds = () => {
    const [list, setList] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);

    const [id, setId] = useState('');
    const [add, setAdd] = useState(true);

    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }
    }
    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }
    }

    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }

    const getCategories = async () => {

        var url = setting.api;
        await fetch(url + '/api/getCategoryList')
            .then(response => response.json())
            .then(json => setList(json)
            );
    }

    useEffect(() => {
        getCategories();

    }, []);

    return (

        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Sidebar />
                {JSON.parse(localStorage.getItem('user')).role === 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Feeds </h2>
                        <Row>

                            <Col >
                                <Card>
                                    <Card.Header className="head">
                                        Feeds
                                    </Card.Header>
                                    <Card.Body>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td>1</td>
                                                    <td>{`${setting.url}/feed`}</td>
                                                    <td>

                                                        <a href={`${setting.url}/feed`} target="_blank"><VisibilityIcon /></a>

                                                    </td>
                                                </tr >
                                                {

                                                    list && list.map((r, index) =>
                                                        <tr key={r.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{`${setting.url}/category/${r.slug}/feed`}</td>
                                                            <td>

                                                                <>
                                                                    <a href={`${setting.url}/category/${r.slug}/feed`} target="_blank"><VisibilityIcon /></a>

                                                                </>

                                                            </td>
                                                        </tr >
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                        <Row>
                                            <Col></Col>
                                            {
                                                currentPage !== 1 ?
                                                    <Col >
                                                        <Button className="center" onClick={clickFirst}>First</Button>{' '}
                                                        <Button className="center" onClick={clickPrev}>Prev</Button>
                                                    </Col> : <Col></Col>
                                            }

                                            <Col ><p className="center">Page {currentPage} /{pageCount}</p></Col>
                                            {
                                                currentPage !== pageCount ?
                                                    <Col >

                                                        <Button className="center" onClick={clickNext}>Next</Button>{' '}
                                                        <Button className="center" onClick={clickLast}>Last</Button>
                                                    </Col> : <Col></Col>
                                            }
                                            <Col></Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Col>
                }
                {
                    JSON.parse(localStorage.getItem('user')).role !== 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Not Allowed</h2>
                    </Col>
                }
            </Row >


        </Container >

    );
}

export default Feeds;