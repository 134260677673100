import { Container, Row, Col, Card, Form, Button, Toast, ToastContainer } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { useHistory, useParams } from "react-router-dom";
import MediaGallery from '../components/MediaGallery';
import Sidebar from "../components/Sidebar";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Topbar from "../components/Topbar";
import CategoryTree from '../components/CategoryTree';
import Footer from '../components/Footer';
import EditIcon from '@mui/icons-material/Edit';
import PublishedIcon from '@mui/icons-material/PublishedWithChanges';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import setting from '../setting.json';
const EditNews = () => {
    const history = useHistory();
    const { id } = useParams();
    const [list, setList] = useState([]);
    const [postStatus, setPostStatus] = useState(false);
    const [content, setContent] = useState('');
    const [newsData, setNewsData] = useState({});
    const [groupid, setGroupid] = useState(0);

    const [preview, setPreview] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [usr, setUsr] = useState(JSON.parse(localStorage.getItem('user')));
    const [startDate, setStartDate] = useState(new Date());

    const [publishToastShow, setPublishToastShow] = useState(false);
    const [successToastShow, setSuccessToastShow] = useState(false);
    const [errorToastShow, setErrorToastShow] = useState(false);
    var url = setting.api;
    var cats = categoryList;
    const selectCategory = (event) => {
        if (event.target.checked === false) {
            const index = cats.indexOf(event.target.value);
            if (index > -1) {
                cats.splice(index, 1);
            }
        } else {
            cats.push(event.target.value);
        }
        setCategoryList(cats);
    }


    const showMetaInfo = (event) => {
        if (event.target.checked === true) {

            document.getElementById('meta1').style.display = "block";
            document.getElementById('meta2').style.display = "block";
            document.getElementById('meta3').style.display = "block";
            document.getElementById('meta4').style.display = "block";
        } else {

            document.getElementById('meta1').style.display = "none";
            document.getElementById('meta2').style.display = "none";
            document.getElementById('meta3').style.display = "none";
            document.getElementById('meta4').style.display = "none";
        }
    }

    const getCategories = async () => {
        var url = setting.api;
        var user = JSON.parse(localStorage.getItem('user')).id;

        await fetch(url + '/api/getAllCategoriesByUserId/' + user)
            .then(response => response.json())
            .then(json => {
                setList(json);

            }
            );
    }
    const getNewsById = async () => {
        await fetch(url + '/api/getNewsById/' + id)
            .then(response => response.json())
            .then(json => {
                setNewsData(json);
                setCategoryList(json.categories);
            }
            );
    }
    const editSlug = () => {
        document.getElementById('editSlug').style.display = "none";
        document.getElementById('slug').style.display = "block";
    }

    useEffect(() => {

        getNewsById();
        getCategories();
        document.getElementById('slug').style.display = "none";
        document.getElementById('meta1').style.display = "none";
        document.getElementById('meta2').style.display = "none";

        document.getElementById('meta4').style.display = "none";
    }, []);

    useEffect(() => {
        var path = setting.api + '/wp-content/uploads/' + newsData.location + '/' + newsData.image;
        setPreview(path);
        console.log(newsData);
        var postlink = setting.api + '/' + newsData.slug;
        document.getElementById('sluglabel').innerHTML = 'Slug : ' + newsData.slug;
        document.getElementById('viewpost').setAttribute('href', postlink);
        document.getElementById('preview').setAttribute('src', path);
        document.getElementById('title').value = newsData.title;
        document.getElementById('subtitle').value = newsData.subtitle;
        document.getElementById('slug').value = newsData.slug;
        document.getElementById('metatag').value = newsData.tags;
        document.getElementById('metades').value = newsData.metades;

        document.getElementById('alt_meta').value = newsData.alt_meta;
        if (newsData.post_status === '0')
            setPostStatus(false);

        else
            setPostStatus(true);
        setGroupid(newsData.featured_group_id);
    }, [newsData]);
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2),
            hours = ("0" + date.getHours()).slice(-2),
            minutes = ("0" + date.getMinutes()).slice(-2);
        var dt = date.getFullYear() + '-' + mnth + '-' + day + ' ' + hours + ':' + minutes + ':00';
        return dt;
    }
    function convertToJsDate(str) {
        var dateParts = str.split("-");
        var jsDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0, 2));
    }
    const updateNews = async (event) => {
        event.preventDefault();
        //var date = new Date(startDate);
        //document.getElementById('save1').innerHTML = 'Updating...';
        //document.getElementById('save2').innerHTML = 'Updating...';
        var title = document.getElementById('title').value;
        //console.log(title);
        if (title !== '') {
            try {
                const formData = new FormData();
                formData.append("title", title);
                formData.append("subtitle", document.getElementById('subtitle').value);
                formData.append("slug", document.getElementById('slug').value);
                formData.append("pubdate", convert(startDate));
                formData.append("content", content);
                formData.append("category", cats);
                formData.append("metatag", document.getElementById('metatag').value);
                formData.append("metades", document.getElementById('metades').value);

                formData.append("alt_meta", document.getElementById('alt_meta').value);
                formData.append("group_id", document.getElementById('groupid').value);
                formData.append("id", id);
                //console.log(formData);
                //console.log('test');

                var url = setting.api;
                fetch(url + '/api/updateNews', {
                    method: 'POST',
                    body: formData
                })
                    .then(response => response.json())
                    .then(json => {
                        console.log(json);
                        //toast.success("Post Created Successfully.");
                        //document.getElementById('save1').innerHTML = 'Update';
                        //document.getElementById('save2').innerHTML = 'Update';
                        setSuccessToastShow(true);
                        // history.push('/editNews/' + json);
                    }
                    );

            } catch (err) {
                console.log(err);
                setErrorToastShow(true);
            }

        } else {
            alert('Please enter title');
        }

    }

    const publishDraftNews = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append("title", document.getElementById('title').value);
            formData.append("subtitle", document.getElementById('subtitle').value);
            formData.append("slug", document.getElementById('slug').value);
            formData.append("pubdate", convert(startDate));
            formData.append("content", content);
            formData.append("category", categoryList);
            formData.append("metatag", document.getElementById('metatag').value);
            formData.append("metades", document.getElementById('metades').value);

            formData.append("alt_meta", document.getElementById('alt_meta').value);
            formData.append("group_id", document.getElementById('groupid').value);
            formData.append("id", id);
            console.log(formData);
            await fetch(url + '/api/publishDraftNews/',
                {
                    method: 'POST',
                    body: formData
                })
                .then(response => response.json())
                .then(json => {
                    setPublishToastShow(true);
                    setPostStatus(true);
                }
                );
        } catch (err) {
            console.log(err);
        }
    }
    const updateDraftNews = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append("title", document.getElementById('title').value);
            formData.append("subtitle", document.getElementById('subtitle').value);
            formData.append("slug", document.getElementById('slug').value);
            formData.append("content", content);
            formData.append("category", categoryList);
            formData.append("metatag", document.getElementById('metatag').value);
            formData.append("metades", document.getElementById('metades').value);

            formData.append("alt_meta", document.getElementById('alt_meta').value);
            formData.append("group_id", document.getElementById('groupid').value);
            formData.append("id", id);

            await fetch(url + '/api/updateDraftNews/',
                {
                    method: 'POST',
                    body: formData
                })
                .then(response => response.json())
                .then(json => {
                    setSuccessToastShow(true);
                    history.push('/draftNews');
                }
                );
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Container fluid id="contnr">
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>

                <Sidebar />
                <Col md={10} lg={10} className="rightsect">
                    <h3 className="pagetitle">
                        <EditIcon className="titleicon" /> Edit  News {'  '}
                        <a href="#" id="viewpost" target="_blank" rel="noreferrer">View Post</a>
                    </h3>

                    <Form>
                        <Row>
                            <Col md={9} >
                                <Card >
                                    <Card.Body>
                                        <Form.Group className="mb-3" controlId="title">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control type="text" placeholder="Enter title" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="slug">
                                            <Form.Label id="sluglabel">Slug</Form.Label>{' '}
                                            <Button variant="light" id="editSlug" onClick={editSlug} >Edit</Button>
                                            <Form.Control type="text" placeholder="Enter slug" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="subtitle">
                                            <Form.Label>Sub Title</Form.Label>
                                            <Form.Control type="text" placeholder="Enter sub title" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="content">
                                            <Form.Label>Content</Form.Label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{
                                                    ckfinder: {
                                                        //uploadUrl: setting.api + '/api/uploadImageFromCk'
                                                        uploadUrl: setting.api + '/api/uploadImageFromCk'
                                                    }
                                                }}
                                                data={newsData.contentencoded}
                                                onReady={editor => {

                                                    editor.editing.view.change((writer) => {
                                                        writer.setStyle(
                                                            "height",
                                                            "350px",
                                                            editor.editing.view.document.getRoot()
                                                        );
                                                    });
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setContent(data);
                                                    // console.log({ event, editor, data });
                                                }}
                                                onBlur={(event, editor) => {
                                                    //console.log('Blur.', editor);
                                                }}
                                                onFocus={(event, editor) => {
                                                    //console.log('Focus.', editor);
                                                }}
                                            />
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <Card className="mt-20">
                                    <Card.Body>
                                        <Form.Group controlId="custommeta">
                                            <Form.Check

                                                type='checkbox'
                                                className=""
                                                value=""
                                                label="Custom Meta Info"
                                                onChange={showMetaInfo}
                                            />
                                        </Form.Group>

                                        <Form.Group id="meta1" controlId="metatag">
                                            <Form.Label>Meta Tag (Seperated by comma )</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Meta Tag" />
                                        </Form.Group>
                                        <Form.Group id="meta2" controlId="metades">
                                            <Form.Label>Meta Description</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Meta Description" />
                                        </Form.Group>

                                        <Form.Group id="meta4" controlId="alt_meta" >
                                            <Form.Label>Image ALT Text</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Image ALT Text" />
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={3}>

                                <Card >
                                    <Card.Header className="cardhead">Category</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md={12} className="mb-3 catsect">
                                                <CategoryTree
                                                    list={list}
                                                    selectCategory={selectCategory}
                                                    newscategory={newsData.categories}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card className="mt-20">
                                    <Card.Header className="cardhead">Featured Image (jpg, png)</Card.Header>
                                    <Card.Body>

                                        <MediaGallery img={preview} groupid={groupid} />
                                    </Card.Body>
                                </Card>
                                <Card className="mt-20">
                                    <Card.Header className="cardhead">Publish</Card.Header>
                                    <Card.Body >
                                        <div className="d-grid gap-2">

                                            {(usr.role === 'editor' && !postStatus) || (usr.role === 'correspondent' && !postStatus && usr.permission === '2') ? <Button variant="outline-secondary" type="submit" id="update" onClick={publishDraftNews}>Publish </Button> : ''}


                                            <p><b><PublishedIcon /> Status : </b> {postStatus === true ? 'Published' : 'Draft'}</p>

                                            <p><b><CalendarMonthIcon /> Published On : </b>{newsData.pubDate} </p>




                                            {(usr.role === 'editor') || (usr.role === 'correspondent' && usr.permission === '1' && postStatus === false) || (usr.role === 'correspondent' && usr.permission === '2' && postStatus === false) || (usr.role === 'correspondent' && usr.permission === '2' && postStatus === true) || usr.role === 'admin' ? <Button variant="primary" type="submit" id="save1" onClick={updateNews}>Update</Button> : ''}


                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Footer />
            <ToastContainer position='bottom-end'>
                <Toast bg='warning' onClose={() => setPublishToastShow(false)} show={publishToastShow} delay={3000} autohide >
                    <Toast.Body>News published successfully</Toast.Body>
                </Toast>
                <Toast bg='warning' onClose={() => setSuccessToastShow(false)} show={successToastShow} delay={3000} autohide >
                    <Toast.Body>News updated successfully</Toast.Body>
                </Toast>
                <Toast bg='danger' onClose={() => setErrorToastShow(false)} show={errorToastShow} delay={3000} autohide >
                    <Toast.Body>News updation error</Toast.Body>
                </Toast>
            </ToastContainer>
        </Container>
    );
}
export default EditNews;